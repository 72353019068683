import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { css } from 'styled-components/macro';
import SEO from '../components/SEO';
import { Link } from 'gatsby';
import {
  Article,
  Main,
  Container,
  LinkBtnTransparent,
} from '../components/Elements';
import { Box, Heading, Text, Flex } from 'rebass';
import Img from 'gatsby-image';
import Header from '../components/Header';
import FooterPre from '../components/FooterPre';
import _map from 'lodash/map';
import NetlifyForm3 from '../components/FormNetlify3';
import fiText from '../locales/fi.js';
import { HeroBlogPost } from '../components/HeroBlogPost';

const BlogPost = ({ data }) => {
  const {
    title,
    seoMetaTags,
    content,
    kuva,
    julkaistu,
    showDateBool,
  } = data.project;
  const { edges } = data.thumbs;

  const showDate = () => {
    if (showDateBool) {
      if (julkaistu === undefined) {
        return null;
      } else {
        let date = new Date(julkaistu);
        let d = date.getDate();
        let m = date.getMonth() + 1;
        let y = date.getFullYear();
        let euDate = `${d}.${m}.${y}`;
        return euDate;
      }
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <div className="hamburgerColorBlue">
        <Header logo="white" />
      </div>

      <Main
        bg={'peachBg'}
        pt={7}
        css={`
          padding-top: 60px !important;
          section:first-child {
            height: 700px;
          }
          article {
            position: relative;
            margin-top: -140px;
            z-index: 100;
          }
          @media (max-width: 1080px) {
            section:first-child {
              height: 500px;
            }
            article {
              margin-top: 0;
            }
          }
          @media (max-width: 1080px) {
            section:first-child {
              height: 50vh;
            }
            article {
              margin-top: 0;
            }
          }
        `}
      >
        <HeroBlogPost imgHero={kuva} />
        <Article
          as="article"
          bg="#fff"
          color="darkBlue"
          maxWidth={1088}
          mx={'auto'}
          mt={[1, 3]}
        >
          <Heading
            as="h1"
            textAlign="center"
            fontSize={[4, 6]}
            lineHeight={'1.34'}
            fontWeight={'600'}
            maxWidth="980px"
            mx="auto"
            sx={{
              textTransform: 'uppercase',
              letterSpacing: '1.4px',
            }}
          >
            {title}
          </Heading>
          <Text textAlign="center" fontSize={1} pb={2} letterSpacing="1px">
            {showDate()}
          </Text>
          <Box
            maxHeight="580px"
            overflow="hidden"
            mx="auto"
            maxWidth="980px"
          ></Box>
          <Box maxWidth="720px" mx="auto" my={3}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Box>
          <div
            css={`
              margin-top: 30px;
              padding: 72px 0 0;
              text-align: center;
              display: none;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: #b09958;
              > h2.lataaEsiteOtsikko {
                font-size: 42px;
                font-family: utopia-std;
                letter-spacing: 2px;
                text-transform: uppercase;
              }
              p {
                max-width: 600px;
                margin-bottom: 0;
              }
              form {
                max-width: 900px;
                width: 100%;
                input {
                  color: #b09958;
                }
              }
              form input::placeholder {
                color: #b09958;
                opacity: 0.8;
              }
            `}
          >
            <h2 className="lataaEsiteOtsikko">{data.lataaEsite.otsikko}</h2>
            <p>{data.lataaEsite.teksti}</p>
            <a
              href={data.lataaEsite.esite.url}
              target="_blank"
              className="downloadLink"
              css={{ marginTop: 20, textDecoration: 'underline' }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                  fill="#B09958"
                />
              </svg>
              Lataa kohde-esite (PDF)
            </a>
            <a
              href={data.lataaEsite.esite2.url}
              target="_blank"
              className="downloadLink"
              css={{ marginTop: 10, textDecoration: 'underline' }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 9H19L12 16L5 9H9V3H15V9ZM5 20V18H19V20H5Z"
                  fill="#B09958"
                />
              </svg>
              Lataa sisustustyylikooste (PDF)
            </a>
            {/*<NetlifyForm3
              urlEsite={data.lataaEsite.esite.url}
              urlEsiteSisustus={data.lataaEsite.esite2.url}
            />*/}
          </div>
        </Article>
        <Container textAlign="center">
          <Text
            mx="auto"
            mt={5}
            fontSize={'38px'}
            fontFamily="utopia-std"
            textTransform="unset"
            fontWeight={400}
          >
            {fiText.continueReading}
          </Text>
        </Container>
        <Flex maxWidth={1088} mx="auto" px={[1, 1, 0]} mt={3} flexWrap="wrap">
          {_map(edges, thumb => (
            <Flex key={thumb.node.id} pb={3} width={[1, 1 / 2]}>
              <Link className="aStretch" to={`/blogi/${thumb.node.slug}/`}>
                <Box
                  width="90%"
                  mb={2}
                  mx="auto"
                  maxWidth="500px"
                  maxHeight="277px"
                  overflow="hidden"
                >
                  <Img
                    fluid={thumb.node.kuva.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt=""
                  />
                </Box>
                <Heading
                  as="h3"
                  mx="auto"
                  fontSize={5}
                  pb={3}
                  color="darkBlue"
                  fontWeight="600"
                  lineHeight="34px"
                  width="90%"
                  maxWidth="500px"
                  textAlign="left"
                >
                  {thumb.node.title}
                </Heading>
              </Link>
            </Flex>
          ))}
        </Flex>
        <Flex mb={60} justifyContent="center">
          <LinkBtnTransparent to="/blogi">
            {fiText.showAllArticles}
          </LinkBtnTransparent>
        </Flex>
      </Main>
      <FooterPre />
    </Fragment>
  );
};

export const projectQuery = graphql`
  query($slug: String!) {
    lataaEsite: datoCmsLataaEsite {
      otsikko
      teksti
      esite {
        url
      }
      esite2 {
        url
      }
    }
    project: datoCmsBlogpost(slug: { eq: $slug }) {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      kuva {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      julkaistu
      showDateBool
      content
    }
    thumbs: allDatoCmsBlogpost(
      limit: 2
      filter: { slug: { ne: $slug } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          kuva {
            fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogPost;
