import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components/macro';
import BackgroundImage from 'gatsby-background-image';
import { Heading, Text, Box, Flex } from 'rebass';
import { Btn } from '../components/Elements';
import Fade from 'react-reveal/Fade';
import scrollTo from 'gatsby-plugin-smoothscroll';

const BackgroundSection = ({ imgHero }) => {
  console.log(imgHero);
  return (
    <BackgroundImage
      className="heroImage"
      Tag="section"
      fluid={imgHero.fluid}
    ></BackgroundImage>
  );
};

export const HeroBlogPost = styled(BackgroundSection)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
`;
